body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Yavome';
  src: local('Yavome'), url(./assets/YavomeRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'Gotham';
  /* src: local('Gotham'), url(./assets/GothamLight.otf) format('opentype'); */
  src: local('Gotham'), url(./assets/JostVariableFont_wght.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body, html {
  font-size: 18px;
  font-family: 'Gotham', sans-serif;
}

h1 { font-family: 'Gotham', sans-serif; }
h2 { color: #000 !important; }

h2 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 30px;
  margin: 20px 0;
  padding: 0;
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
form {
  margin-bottom: 20px;
}